@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}


@keyframes fadeIn {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.animate-fadeIn {
  animation: fadeIn 2s forwards;
}

.animate-slideIn {
  animation: slideIn 2s forwards;
}



*::-webkit-scrollbar {
  display: none;
}

* {
-ms-overflow-style: none; /* IE and Edge */
scrollbar-width: none; /* Firefox */
}



body {

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Supprime la scrollbar uniquement pour le contenu visible */


h1,h2 {
  color: rgb(81, 197, 17); /* Couleur verte */
  font-size: 10rem; /* Augmenter la taille à 10rem */
  font-weight: bold; /* Texte en gras */
  background-color: white; /* Surligner en blanc */
  padding: 0.5rem; /* Ajouter un peu d'espace autour du texte */
  max-width: 33.33%; /* Largeur maximale pour les écrans plus larges */
  margin: 2rem auto 0; /* Ajouter une marge en haut, centrer le titre horizontalement */
  border-radius: 0.5rem; /* Arrondir légèrement les coins du fond */
}


/* Media query pour les écrans mobiles */
@media (max-width: 768px) {
  h1,h2 {
    max-width: 90%; /* Prendre 90% de la largeur sur mobile */
    font-size: 5rem; /* Ajuster la taille de la police si nécessaire */
  }
}